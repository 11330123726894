<template>
  <div class="destinations">
    <h1>Destinations</h1>
    <DestinationList :destinations="destinations" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import DestinationList from '@/components/DestinationList.vue'

export default defineComponent({
  name: 'DestinationsView',
  components: {
    DestinationList
  },
  setup() {
    const store = useStore()
    const destinations = computed(() => store.getters.getDestinations)

    return {
      destinations
    }
  }
})
</script>
