import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-image" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "card-content" }
const _hoisted_5 = { class: "description" }
const _hoisted_6 = { class: "location" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: { name: 'destination-detail', params: { id: _ctx.destination.id } }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.thumbnailUrl,
            alt: _ctx.destination.name,
            class: "thumbnail"
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.destination.name), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.truncatedDescription), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.destination.location.city) + ", " + _toDisplayString(_ctx.destination.location.country), 1)
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}