<template>
  <div class="destination-list">
    <div class="sidebar">
      <BaseSidebar @filter-changed="applyFilters" />
    </div>
    <div class="main-content">
      <div class="cards-container">
        <DestinationCard
          v-for="destination in paginatedDestinations"
          :key="destination.id"
          :destination="destination"
        />
      </div>
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1" class="btn">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="btn">Next</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import DestinationCard from '@/components/DestinationCard.vue';
import BaseSidebar from '@/components/BaseSidebar.vue';
import { Destination } from '@/types';

export default defineComponent({
  name: 'DestinationList',
  components: {
    DestinationCard,
    BaseSidebar,
  },
  setup() {
    const store = useStore();
    const currentPage = ref(1);
    const itemsPerPage = 10;
    const filters = ref({});

    const destinations = computed(() => store.state.destinations);
    const filteredDestinations = computed(() => {
      // Apply filters here
      return destinations.value.filter((dest: Destination) => {
        // Implement your filter logic here
        return true; // For now, return all destinations
      });
    });

    const totalPages = computed(() => Math.ceil(filteredDestinations.value.length / itemsPerPage));

    const paginatedDestinations = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      return filteredDestinations.value.slice(start, end);
    });

    const prevPage = () => {
      if (currentPage.value > 1) currentPage.value--;
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) currentPage.value++;
    };

    const applyFilters = (newFilters: object) => {
      filters.value = newFilters;
      currentPage.value = 1; // Reset to first page when filters change
    };

    onMounted(() => {
      store.dispatch('fetchDestinations');
    });

    return {
      paginatedDestinations,
      currentPage,
      totalPages,
      prevPage,
      nextPage,
      applyFilters,
    };
  },
});
</script>

<style scoped>
.destination-list {
  display: flex;
  gap: 2rem;
}

.sidebar {
  width: 250px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.main-content {
  flex: 1;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.pagination button {
  padding: 0.5rem 1rem;
}

.pagination span {
  font-weight: 600;
}
</style>
