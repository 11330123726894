import { createStore } from 'vuex'
import axios from 'axios'
import { Destination } from '@/types'

const apiUrl = process.env.VUE_APP_API_URL || 'http://localhost:8000'

export default createStore({
  state: {
    destinations: [] as Destination[],
    currentCity: 'la-paz'
  },
  mutations: {
    SET_DESTINATIONS(state, destinations: Destination[]) {
      state.destinations = destinations
    },
    SET_CURRENT_CITY(state, city: string) {
      state.currentCity = city
    }
  },
  actions: {
    async fetchDestinations({ commit, state }) {
      try {
        const response = await axios.get(`${apiUrl}/places/?city=${state.currentCity}`)
        commit('SET_DESTINATIONS', response.data)
      } catch (error) {
        console.error('Error fetching destinations:', error)
      }
    },
    setCurrentCity({ commit, dispatch }, city: string) {
      commit('SET_CURRENT_CITY', city)
      dispatch('fetchDestinations')
    }
  },
  getters: {
    getDestinations: (state) => state.destinations,
    getCurrentCity: (state) => state.currentCity
  }
})
