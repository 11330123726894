import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DestinationsView from '../views/DestinationsView.vue'
import DestinationDetailView from '../views/DestinationDetailView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home-destinations',
    component: DestinationsView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/destinations',
    name: 'destinations',
    component: DestinationsView
  },
  {
    path: '/destinations/:id',
    name: 'destination-detail',
    component: DestinationDetailView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
