import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "destination-list" }
const _hoisted_2 = { class: "sidebar" }
const _hoisted_3 = { class: "main-content" }
const _hoisted_4 = { class: "cards-container" }
const _hoisted_5 = { class: "pagination" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSidebar = _resolveComponent("BaseSidebar")!
  const _component_DestinationCard = _resolveComponent("DestinationCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseSidebar, { onFilterChanged: _ctx.applyFilters }, null, 8, ["onFilterChanged"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginatedDestinations, (destination) => {
          return (_openBlock(), _createBlock(_component_DestinationCard, {
            key: destination.id,
            destination: destination
          }, null, 8, ["destination"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prevPage && _ctx.prevPage(...args))),
          disabled: _ctx.currentPage === 1,
          class: "btn"
        }, "Previous", 8, _hoisted_6),
        _createElementVNode("span", null, "Page " + _toDisplayString(_ctx.currentPage) + " of " + _toDisplayString(_ctx.totalPages), 1),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args))),
          disabled: _ctx.currentPage === _ctx.totalPages,
          class: "btn"
        }, "Next", 8, _hoisted_7)
      ])
    ])
  ]))
}