import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "destination-detail"
}
const _hoisted_2 = { class: "location" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { class: "photo-gallery" }
const _hoisted_6 = {
  key: 0,
  class: "photo-grid"
}
const _hoisted_7 = ["src", "alt", "onClick"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 1,
  class: "loading"
}
const _hoisted_10 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.destination)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.destination.name), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(_ctx.destination.city) + ", " + _toDisplayString(_ctx.destination.country), 1),
            (_ctx.destination.town)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.destination.town), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", null, _toDisplayString(_ctx.destination.description), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Photos", -1)),
            (_ctx.destination.photos.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.destination.photos, (photo) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: photo.id,
                      class: "photo-item"
                    }, [
                      _createElementVNode("img", {
                        src: photo.image,
                        alt: photo.caption,
                        onClick: ($event: any) => (_ctx.openPhotoModal(photo))
                      }, null, 8, _hoisted_7)
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("p", _hoisted_8, "No photos available"))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, "Loading...")),
    (_ctx.selectedPhoto)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "photo-modal",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePhotoModal && _ctx.closePhotoModal(...args)))
        }, [
          _createElementVNode("img", {
            src: _ctx.selectedPhoto.image,
            alt: _ctx.selectedPhoto.caption
          }, null, 8, _hoisted_10),
          _createElementVNode("p", null, _toDisplayString(_ctx.selectedPhoto.caption), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}