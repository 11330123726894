<template>
  <div class="card">
    <router-link :to="{ name: 'destination-detail', params: { id: destination.id } }">
      <div class="card-image">
        <img :src="thumbnailUrl" :alt="destination.name" class="thumbnail">
      </div>
      <div class="card-content">
        <h2>{{ destination.name }}</h2>
        <p class="description">{{ truncatedDescription }}</p>
        <p class="location">{{ destination.location.city }}, {{ destination.location.country }}</p>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { Destination } from '@/types';

export default defineComponent({
  name: 'DestinationCard',
  props: {
    destination: {
      type: Object as () => Destination,
      required: true,
    },
  },
  setup(props) {
    const thumbnailUrl = computed(() => {
      if (props.destination.photos && props.destination.photos.length > 0) {
        return props.destination.photos[0].image;
      }
      return '/path/to/placeholder-image.jpg';
    });

    const truncatedDescription = computed(() => {
      return props.destination.description.length > 100
        ? props.destination.description.slice(0, 100) + '...'
        : props.destination.description;
    });

    return {
      thumbnailUrl,
      truncatedDescription,
    };
  },
});
</script>

<style scoped>
.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-image {
  height: 200px;
  overflow: hidden;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card:hover .thumbnail {
  transform: scale(1.05);
}

.card-content {
  padding: 1.5rem;
}

h2 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #2c3e50;
}

.description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.location {
  font-size: 0.8rem;
  color: #3498db;
  font-weight: 600;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>

